/*
 * Utility functions
 */
import Vue from 'vue/dist/vue.js';
import store from '@/stores/store.js';
import { mixinDateUtil, LocalDate } from './dateUtil.js';



//Get measure for date
export function ugetMeasure(date){
  return store.getters.measures[date.dateFormat()];
}

//Get last measure preceding given date
export function ugetLastMeasure(date = LocalDate.now()){
  var measures = store.getters.measures;
  //Filter each measure before date, sort by date and return the last measure if it exist
  var m = Object.values(measures).filter(measure => { return measure.date < date; });
  m.sort((m1,m2) => m1.date-m2.date);
  return m.length > 0 ? m[m.length-1] : { date: LocalDate.new(0), weight: 0 };
}

//Get model for date
export function ugetModel(date){
  return store.getters.models[date.dateFormat()];
}

//Get last model preceding given date
export function ugetLastModel(date = LocalDate.now()){
  var models = store.getters.models;
  //Filter each models before date, sort by date and return the last model if it exist
  var m = Object.values(models).filter(model => { return model.date < date; });
  m.sort((m1,m2) => m1.date-m2.date);
  return m.length > 0 ? m[m.length-1] : { date: LocalDate.new(0), threshold_power: 0, anaerobic_capacity: 0, max_power: 0, stamina: 0 };
}

//Get current model taking into account if there is a set model
export function ugetSetModel(date = LocalDate.now()){
  let model = ugetLastModel(date);
  let setModel = store.getters.set_model;
  if(!setModel)
    return model;

  let currentModel = Object.assign({}, model); //clone last model
  for(const [key, value] of Object.entries(setModel)){ //And set values of set model that aren't 0 or null
    if(value)
      currentModel[key] = value;
  }

  if(currentModel.map && currentModel.threshold_power && currentModel.map > currentModel.threshold_power){ //If MAP is defined
    currentModel.anaerobic_capacity = (currentModel.map-currentModel.threshold_power)*300; //Recalculate anaerobic capacity (diff between map and ftp over 5 minutes)
  }

  return currentModel;
}

//Return activity with given serial number if it exist
export function ugetActivityFromSerial(serial_number){
  var activities = store.getters.activities;
  for(var i=0; i<activities.length; i++){
    if(activities[i].serial_number == serial_number)
      return activities[i];
  }
  return null;
}

//Check if activity with serial exist
export function userialExist(serial_number){
  if(ugetActivityFromSerial(serial_number))
    return true;
  return false;
}


function getById(id, elements){
  if(!id)
    return null;

  for(var i=0; i<elements.length; i++){
    if(elements[i].id == id)
      return elements[i];
  }
  return null;
}


//Get workout by its id
export function ugetWorkout(id){
  return getById(id, store.getters.workouts);
}

//Get Training ride by its id
export function ugetTrainingRide(id){
  return getById(id, store.getters.trainingRides);
}

//Get activities list for date
export function ugetActivitiesForDay(date){
  return store.getters.activities.filter(activity => activity.date.isSameDay(date));
}
//Get workouts list for date
export function ugetWorkoutsForDay(date){
  return store.getters.workouts.filter(workout => workout.date.isSameDay(date));
}
//Get objectives list for date
export function ugetObjectivesForDay(date){
  return store.getters.objectives.filter(objective => objective.date.isSameDay(date));
}
//Get training rides list for date
export function ugetTrainingRidesForDay(date){
  return store.getters.trainingRides.filter(trainingRide => trainingRide.date.isSameDay(date));
}

//Get training block that contain given date if it exist
export function ugetTrainingBlockForDay(date){
  for(let block of this.$store.getters.trainingBlocks){
    if(date >= block.start && date < block.end){
      return block;
    }
  }
  return null;
}

//Check if user has a generated training plan
export function $planExist(){
  if(!this.$store.getters.user)// || !this.$store.getters.hasPremiumAccess)
    return false;

  var today = LocalDate.today();
  for(let block of this.$store.getters.trainingBlocks){
    if(block.end >= today)
      return true;
  }
  return false;
}

//Calc normalized power of power data
export function calcNPower(powerData){
  var npowerData = [];

  //30sec rolling average from the beginning of the data
  var sum = 0;
  const NBSIDE = 12; //moving average with a window of 25 (12+1+12)
  var nb = NBSIDE;
  for(let i=0; i<NBSIDE; i++){
    sum+= powerData[i];
  }
  for(let i=0; i<powerData.length; i++){
    npowerData[i] = sum/nb;
    if(i > NBSIDE){
      sum-= powerData[i-NBSIDE];
      nb--;
    }
    if(i+NBSIDE < powerData.length){
      sum+= powerData[i+NBSIDE];
      nb++;
    }
  }

  //Raise values to the fourth power
  for(let i=0; i<npowerData.length; i++)
    npowerData[i] = Math.pow(npowerData[i],4);

  //Average
  var avg = 0;
  for(let i=0; i<npowerData.length; i++)
    avg+= npowerData[i];
  avg/= npowerData.length;

  //Fourth root of average
  var result = Math.round(Math.pow(avg, 1/4));

  return result;
}


export function $getAlternates(route){
  let links = [];
  links.push({ rel: 'alternate', hreflang: 'fr', href: 'https://app.vo2fast.com/fr/'+route });
  links.push({ rel: 'alternate', hreflang: 'en', href: 'https://app.vo2fast.com/en/'+route });
  links.push({ rel: 'alternate', hreflang: 'x-default', href: 'https://app.vo2fast.com/'+route });
  //links.push({ rel: 'alternate', hreflang: 'x-default', href: 'https://app.vo2fast.com/'});
  return links;
}


export function mixinUtil(){
  mixinDateUtil();
  Vue.mixin({
    methods: {
      ugetMeasure,
      ugetLastMeasure,
      ugetModel,
      ugetLastModel,
      ugetSetModel,
      ugetActivityFromSerial,
      userialExist,
      ugetWorkout,
      ugetTrainingRide,
      ugetActivitiesForDay,
      ugetWorkoutsForDay,
      ugetObjectivesForDay,
      ugetTrainingRidesForDay,
      ugetTrainingBlockForDay,
      $planExist,
      calcNPower,
      $getAlternates,
    }
  });
}

module.exports = `
<section>
  <h1>Commencer avec VO2Fast</h1>
  <p>
    VO2Fast est une plateforme web qui vous aide pour l'analyse de votre entraînement et pour la préparation de vos objectifs.<br/>
    Pour commencer, voici quelques étapes que vous pouvez faire dés le début de votre utilisation de VO2Fast:
  </p>
</section>
<section>
  <h3>Importer vos activités</h2>
    <p>
      Pour obtenir des analyses correctes, vous devez d'abord importez vos activités. Vous avez la possibilité de télécharger vos fichiers d'activités
      via le bouton d'upload <i class="mdi mdi-file-upload"></i> (Vous pouvez télécharger plusieurs fichiers en une fois),
      ou vous pouvez également synchroniser votre compte avec Garmin Connect ou Strava sur la page des paramètres <i class="mdi mdi-cog"></i>.<br/>
      Après la synchronisation, les activités des 3 derniers mois ainsi que toute les futures activités que vous effecturez seront automatiquement téléchargé (La synchronisation peut prendre quelques minutes et vous devrez recharger la page pour voir les activités ajoutés).<br/>
      Vous pouvez également ajouter manuellement une activité depuis la page du calendrier si vous n'avez pas les données sauvegardé.
    </p>
</section>
<section>
  <h3>Mesures et modèles</h2>
    <p>
      A partir de chaque activité téléchargée, VO2Fast évaluera automatiquement un modèle de puissance (seuil, puissance max, capacité anaérobique) et de fréquence cardiaque (seuil, max) et mettra à jour tout changement détecté.
      Ces valeurs peuvent être utilisées pour suivre les progrès et votre entraînement (temps passé dans les zones de puissance, stress d'entraînement, etc ...). <br/>
      Sur la page du calendrier, vous pouvez également saisir vos mesures (peut contenir votre poids et votre fréquence cardiaque au repos pour compléter le modèle de FC). <br/>
      Ces données sont également utiles pour obtenir des analyses correctes. Les données de mesures peuvent être mises à jour pour chaque jour si nécessaire (vous permettant de suivre les changements). <br/>
      Si une activité a des données de puissance ou de fréquence cardiaque incorrectes qui faussent vos modèles, vous pouvez la faire ignorer dans le calcul (édition -> cocher ignorer puissance ou fc).
    </p>
</section>
<section>
  <h3>Générez votre plan d'entraînement</h3>
  <p>
    Sur la page générer un plan d'entraînement, vous pourrez génerez votre plan personalisé. L'outil vous demandera quelques informations (votre niveau d'entraînement, vos disponibilités, vos objectifs). Une fois rempli votre plan sera généré en quelques minutes.<br/>
    Vous pourrez ensuite voir toute les séances planifiées durant les semaines qui suivent sur votre calendrier et votre tableau de bord.<br/>
    Pour chaque semaine, il vous sera possible de modifier vos disponibilités, le plan se mettra à jour automatiquement. Le plan se mettra à jour à chaque changement (ajout ou suppression) d'objectif secondaire dans la semaine.
  </p>
</section>
<section>
  <h3>Suivre les séances</h3>
  <p>
    Si vous êtes synchronisé avec Garmin connect, les séances des 7 prochains jours seront automatiquement envoyé sur Garmin Connect. Vous pourrez ainsi suivre les séances sur votre appareil Garmin. Pour plus d'informations, <a href="https://vo2fast.com/blog/suivre-une-seance-sur-garmin/">vous pouvez lire cet article</a>.<br/>
    Vous pouvez également exporter toute les séances en fichier pour Home Trainer (erg/mrc) et pour Zwift. Pour plus d'informations pour obtenir les séances sur Zwift, <a href="https://www.zwift.com/news/11792-importing-and-sharing-custom-zwift-workouts">cliquez ici.</a>
  </p>
</section>
<section>
<section>
  <h3>Suivi de votre entraînement</h3>
  <p>
    Sur la page performances, vous pourrez suivre l'évolution des votre profil de puissance, votre stress d'entraînement, votre fatigue et votre forme au fil du temps. <br/>
    Vous aurez aussi vos meilleures tendances de puissance (meilleure puissance moyenne sur une durée définie ainsi que la date à laquelle cela s'est produit. Vous pouvez voir l'activité où cela s'est produit en cliquant sur la valeur que vous voulez voir). <br/>
    Vos courbes de puissance et de fréquence cardiaque générales seront aussi disponibles.
  </p>
</section>
`;

const frenchNotifications = require('./frenchNotifications');
const frenchDoc = require('./frenchDoc');
const frenchWorkouts = require('./frenchWorkouts');
const frenchMails = require('./frenchMails');
const frenchPrivacyPolicy = require('./frenchPrivacyPolicy');
const frenchTerms = require('./frenchTerms');
const frenchLegal = require('./frenchLegal');
const frenchLanding = require('./frenchLanding');
const frenchFAQ = require('./frenchFAQ');

module.exports = {
  lang: 'fr',
  nav: {
    login: 'Connexion',
    signup: 'Inscription',
    logout: 'Déconnexion',
    dashboard: 'Tableau de bord',
    calendar: 'Calendrier',
    performances: 'Performances',
    settings: 'Paramètres',
    privacy_policy: 'Politique de confidentialité',
    terms: 'Termes d\'utilisation',
    legal: 'Mentions légales',
    workout_creator: 'Créateur d\'entraînement',
    contact: 'Contact',
    help: 'Aide',
    community: 'Communauté',
    blog: 'Blog',
    roadmap: 'Roadmap',
    forum: 'Forum',
    team: 'L\'équipe',
    features: 'Fonctionnalités',
    faq: 'FAQ',
    members: 'Membres',
    site_map: 'Carte du site',
    business_space: 'Espace pro',
  },
  account: {
    profile: 'Profil',
    your_profile: 'Votre profil',
    complementary_informations: 'Informations complémentaires',
    signup: 'S\'inscrire',
    create_vo2fast_account: 'Créez-vous un compte VO2Fast et gérez vos objectifs et votre planning sportif rapidement et facilement !',
    signup_now: 'S\'inscrire maintenant',
    login: 'Connexion',
    to_login: 'Se connecter',
    not_signed_up: 'Pas encore de compte ?',
    already_have_an_account: 'Déjà un compte ?',
    email: 'Email',
    user_name: 'Nom d\'utilisateur',
    password: 'Mot de passe',
    email_or_username: 'Email ou nom d\'utilisateur',
    confirm_password: 'Confirmer mot de passe',
    new_password: 'Nouveau mot de passe',
    confirm_new_password: 'Confirmer le nouveau mot de passe',
    created: 'Votre compte a été créé !',
    forgot_password: 'Mot de passe oublié ?',
    reset_password: 'Réinitialiser le mot de passe',
    change_password: 'Changer le mot de passe',
    reset_password_mail: 'Un email vous a été envoyé avec les instructions pour procéder au changement de mot de passe.',
    reset_password_success: 'Votre mot de passe a été changé.',
    password_changed: 'Votre mot de passe a été changé.',
    delete_account: 'Supprimer votre compte',
    delete_account_mail: 'Un email vous a été envoyé avec les instructions pour procéder à la suppression de votre compte.',
    delete_account_success: 'Votre compte a été supprimé.',
    email_verification_success: 'Votre email a été vérifié.',
    email_not_verified: 'Votre adresse email n\'a pas encore été vérifié, veuillez vérifier votre boîte mail pour procéder à la vérification. Si vous n\'avez pas reçu le mail, appuyez sur le bouton "Renvoyer le mail" :',
    email_verification_resend: 'Renvoyer le mail',
    location: 'Lieu',
    syncs: {
      activities_syncs: 'Synchronisation des activités',
      connect: 'Connecter',
      connected: 'Connecté',
      disconnect: 'Déconnecter',
      disconnection: 'Déconnexion en cours.',
      garmin_tooltip: 'Autoriser les activités enregistrées sur un appareil Garmin à être synchronisé directement sur votre compte VO2Fast.',
      garmin_training_tooltip: 'Garmin Training permet de pousser directement les séances d\'entraînements planifiés sur vos appareils Garmin.',
      strava_limit: 'Note: A cause des limitations de taux de transferts Strava, il est possible que nous puissions pas charger vos 90 derniers jours d\'activités.',
      want_sync_now: 'Voulez-vous synchronisez vos compteurs maintenant ?',
      guide_sync_now: `Synchroniser vos compteurs permettra d'importer automatiquement vos activités. Cela permettra de calculer votre profil de puissance ou de fréquence cardiaque pour vous donner vos cibles d'entraînement.<br/>
      Synchroniser votre Garmin vous permettra également de retrouver vos séances automatiquement sur votre compteur. <a href="https://vo2fast.com/blog/suivre-une-seance-sur-garmin/" target="_blank">Si vous voulez en savoir plus</a><br/>`,
      click_here_to_sync: 'Pour synchroniser vos compteurs, cliquez ici.',
      sync_later: 'Si vous voulez synchroniser plus tard, vous pourrez le faire depuis la page paramètres.',
      prefer_manual_import: 'Vous pouvez aussi importer vos activités manuellement si vous préférez:',
    },
    username_saved: 'Nom d\'utilisateur sauvegardé.',
    accept_terms: 'En vous inscrivant sur VO2Fast, vous acceptez les <a href="https://app.vo2fast.com/terms">conditions générales d\'utilisation</a> et la <a href="https://app.vo2fast.com/privacy_policy">politique de confidentialité</a>.',
    prove_human: 'Montrez que vous n\'êtes pas un robot',
    riddle: 'Quel est le résultat de ',
    bad_answer: 'Mauvaise réponse !',
    code: 'Code',
    enter_a_code: 'Entrez un code',
    have_a_code: 'Vous avez un code promo ?',
    code_validated: 'Code validé !',
    referrals: {
      referrals: 'Parrainage',
      refer_friend: 'Parrainer un ami',
      offer_month: 'Offrez un mois, recevez un mois !',
      enter_code: 'Si vous avez un code de parrainage, entrez le ici.',
      description: `Vous avez des amis potentiellement interessés par VO2Fast ? Partager leurs ce code ci-dessous à rentrer lors de l'inscription pour leur offrir un mois <b>{type}</b> gratuit ! <br/>
        Pour chaque personnes qui s'abonnent à l'issue de ce mois gratuit, vous recevrez également un mois gratuit.`,
      have_free_month: 'Vous avez un mois d\'essai gratuit !',
      have_free_month_description: `Avec votre code de parrainage, vous avez un mois de VO2Fast {accountType} gratuit.`,
    },
  },
  setup: {
    setup: 'Setup de votre compte',
    success: 'Fin du setup !',
    continue_to_plan: 'Continuer vers la génération d\'un plan ?',
    want_to_continue_to_plan: 'Voulez-vous générez votre premier plan d\'entraînement maintenant ou plus tard ?',
  },
  feedback: {
    title: 'Votre avis nous interesse !',
    rate_app: 'Comment noteriez-vous notre application ?',
    rate_labels: ['Very poor', 'poor', 'average', 'good', 'excellent'],
  },
  general: {
    yes: 'Oui',
    no: 'Non',
    ok: 'Ok',
    see_more: 'Voir plus',
    see_detail: 'Voir le détail',
    welcome: 'Bienvenue',
    required_fields: '*Champs obligatoires',
    file: 'Fichier',
    file_s: 'Fichier(s)',
    send: 'Envoyer',
    edit: 'Éditer',
    modify: 'Modifier',
    save: 'Sauvegarder',
    cancel: 'Annuler',
    delete: 'Supprimer',
    update: 'Mettre à jour',
    back: 'Retour',
    search: 'Rechercher',
    add: 'Ajouter',
    add_item: 'Ajouter un élément',
    description: 'Description',
    title: 'Titre',
    name: 'Nom',
    tag: 'Tag',
    access: 'Accès',
    public: 'Public',
    private: 'Privé',
    select: 'Sélectionner',
    export: 'Exporter',
    close: 'Fermer',
    dropfiles: 'Déposez vos fichiers ici ou cliquez pour les télécharger',
    click_to_select: 'Cliquez pour sélectionner',
    validation: 'Validation',
    validate: 'Valider',
    next: 'Suivant',
    previous: 'Précédent',
    suggestion: 'Suggestion',
    set_default: 'Définir par défaut',
    options: 'Options',
    continue: 'Continuer',
    done: 'Fait',
    stop: 'Arrêter',
    later: 'Plus tard',
    other: 'Autre',
    all: 'Tout',
    accept: 'Accepter',
    copied_to_clipboard: 'Copié dans le presse-papier',
    install: 'Installer',
    install_app: 'Installer l\'application',
    still_beta: '/!\\ Cette fonctionnalité est encore en beta-test.',
    hours: 'Heures',
    minutes: 'Minutes',
    seconds: 'Secondes',
    type: 'type',
  },
  dates: {
    date: 'Date',
    time_day: 'Heure',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    week_days: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
     'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    week: 'Semaine',
    month: 'Mois',
    year: 'Année',
    today: 'Aujourd\'hui',
    tomorrow: 'Demain',
    after_tomorrow: 'Aprés-demain',
  },
  infos: {
    infos: 'Infos',
    informations: 'Informations',
    personal_informations: 'Informations personelles',
    saved: 'Infos sauvegardés.',
    birthday: 'Date de naissance',
    sex: 'Sexe',
    male: 'Homme',
    female: 'Femme',
    age: 'Age',
  },
  metrics: {
    avg: 'moy',
    average: 'Moyenne',
    min: 'min',
    max: 'max',
    distance: 'Distance',
    duration: 'Durée',
    ascent: 'Dénivelé+',
    elevation: 'Dénivelé',
    height: 'Hauteur',
    height_size: 'Taille',
    calories: 'Calories',
    npower: 'NPower',
    load: 'Charge',
    trimp: 'Trimp',
    speed: 'Vitesse',
    heart_rate: 'Fréquence cardiaque',
    hr: 'FC',
    power: 'Puissance',
    cadence: 'Cadence',
    intensity: 'Intensité',
    training_stress: 'Stress d\'entraînement',
    altitude: 'Altitude',
    temperature: 'Temperature',
    wbal: 'Wbal',
    weight: 'Poids',
    threshold: 'Seuil',
    threshold_power: 'Puissance au seuil',
    threshold_heart_rate: 'Fc au seuil',
    ftp: 'FTP',
    pmax: 'PMAX',
    anaerobic: 'Anaérobique',
    aet: 'Aet',
    anaerobic_capacity: 'Capacité anaérobique',
    map: 'PMA',
    max_aerobic: 'PMA',
    max_heart_rate: 'Fc max',
    max_power: 'Puissance max',
    resting_heart_rate: 'Fc repos',
    threshold_hr: 'FC seuil',
    max_hr: 'FC max',
    resting_hr: 'FC repos',
    stamina: 'Endurance',
    meters: 'Mètres',
    feets: 'Pieds',
    kilometers: 'Kilomètres',
    miles: 'Miles',
    kilograms: 'Kilogrammes',
    pounds: 'Pounds',
    celsius: 'Celsius',
    fahrenheit: 'Fahrenheit',
    kelvin: 'Kelvin',
    hrv: 'HRV',
    bpm: 'bpm',
    rpe: 'rpe',
    vo2: 'Vo2',
    vo2_est: 'Vo2 estimé',
    easy: 'Facile',
    endurance: 'Endurance',
    medium: 'Moyenne',
    hard: 'Difficile',
    very_hard: 'Trés Difficile',
    set_model: 'Modèle défini',
    help: {
      weight: 'Votre poids actuel.',
      resting_heart_rate: 'Vous pouvez utiliser un timer et compter votre nombre de battements en restant assis pendant 30 secondes et en doublant le résultat pour trouver votre fréquence cardiaque au repos.',
    },
  },
  zone: {
    zone: 'Zone',
    zones: 'Zones',
    my_zones: 'Mes zones',
    power_zones: 'Zones de puissance',
    heart_rate_zones: 'Zones de fréquence cardiaque',
    recovery: 'Récupération',
    endurance: 'Endurance',
    tempo: 'Tempo',
    threshold: 'Seuil',
    vo2max: 'Vo2Max',
    anaerobic: 'Anaérobique',
    sprint: 'Sprint',
  },
  home: {
    headline: 'Deviens plus rapide !',
    subline: 'Avec le plan d\'entraînement qui vous convient, adapté en fonction de l\'analyse automatique de vos sorties, de vos disponibilités et de vos objectifs.',
    start_training: 'Commencer à s\'entraîner !',
    learn_more: 'En savoir plus',
    plan_card_title: 'Un plan pour votre saison entière',
    plan_card_content: 'Entrez vos objectifs et VO2Fast générera automatiquement les blocs d\'entraînements qu\'il vous faut pour préparer vos objectifs.',
    schedule_card_title: 'Un plan qui s\'adapte à vos disponibilités',
    schedule_card_content: 'Sélectionnez le temps que vous avez de disponible pour chaque jour de la semaine ainsi que le temps moyen que vous souhaitez allouer à l\'entraînement par semaine et le plan s\'adaptera en conséquence.',
    data_card_title: 'Analyse automatique de vos sorties',
    data_card_content: 'Votre profil de puissance (et cardiaque) est mis à jour aprés chaque sortie, vous permettant de traquer votre progression et d\'avoir des entraînements toujours correctement calibrés à votre niveau.',
    how_does_it_work: 'Comment ça marche ?',
  },
  add_item: {
    add_to_calendar: 'Que souhaitez-vous ajouter à votre planning ?',
  },
  dashboard: {
    this_week: 'Cette Semaine',
    statistics: 'Statistiques',
    week_summary: 'Résumé de la semaine',
    total: 'Total',
    power_trends: 'Puissances records',
  },
  news: {
    news: 'News',
  },
  performance: {
    performance: 'Performance',
    performances: 'Performances',
    progression: 'Progression',
    fitness: 'Aptitude',
    fatigue: 'Fatigue',
    form: 'Forme',
    training_load: 'Charge d\'entraînement',
    power_curve: 'Courbe de puissance',
    heart_rate_curve: 'Courbe de FC',
    records: 'Records',
  },
  activity: {
    activity: 'Activitié',
    activities: 'Activités',
    summary: 'Résumé',
    statistics: 'Statistiques',
    course: 'Parcours',
    charts: 'Graphiques',
    records: 'Records',
    data: 'Données',
    planned: 'Prévu',
    done: 'Réalisé',
    power_curve: 'Courbe de puissance',
    heart_rate_curve: 'Courbe de FC',
    selection: 'Sélection',
    upload_activity: 'Importer mes activités',
    manually_upload: 'Importer manuellement mes activités',
    upload_an_activity: 'Importer une activité',
    wait_for_activity: 'Attendre l\'activité Garmin ou Strava',
    no_activity_file: `Je n'ai pas de fichier d'activité.`,
    new_activity: 'Nouvelle activité',
    add_activity: 'Ajouter une activité',
    edit_activity: 'Editer l\'activité',
    activity_name: 'Nom de l\'activité',
    rpe: 'RPE (1-10)',
    sport: 'Sport',
    cycling: 'Vélo',
    running: 'Course à pied',
    strength_training: 'Musculation',
    power_hr_ratio: 'Ratio Puissance/Bpm',
    aerobic_decoupling: 'Découplage aérobique',
    view_strava: 'Voir sur Strava',
    ignore_power: 'Ignorer la puissance',
    ignore_heart_rate: 'Ignorer la FC',
    ignore_power_details: 'Si cette activité contient des données de puissance incorrectes, vous pouvez cochez cette case pour faire ignorer ces données lors des calculs des courbes et des modèles de puissance.',
    ignore_heart_rate_details: 'Si cette activité contient des données de FC incorrectes, vous pouvez cochez cette case pour faire ignorer ces données lors des calculs des courbes et des modèles de FC.',
    rate_activity: 'Notez l\'activité',
    rate_intensity: 'Notez l\'intensité',
  },
  measure: {
    measure: 'Mesure',
    new_measure: 'Nouvelle mesure',
    edit_measure: 'Editer la mesure',
    my_measures: 'Mes mesures',
  },
  model: {
    new_model: 'Nouveau Modèle',
    update_measure: 'Créer une nouvelle mesure à partir de ce modèle',
    no_current_model: 'Pas de modèle actuellement.',
    updated: 'Votre modèle a été mis à jour.',
    current_estimated_model: 'Votre modèle estimé actuel: ',
    set_model_warning: '',
  },
  curves: {
    percent_best: '% du meilleur',
    power_last_days: ' jours de données de puissance.',
    heart_rate_last_days: ' jours de données de FC.',
    power_model: 'Modèle de puissance (estimé)',
  },
  tools: {
    tools: 'Outils',
    direct_link: 'Lien direct vers cet outil',
    ftp_estimator: 'Calculateur de FTP',
    estimated_ftp: 'FTP estimé',
    estimated_anaerobic_capacity: 'Capacité anaérobique estimé',
    best_short_effort: 'Votre meilleur effort court (2-10min)',
    best_long_effort: 'Votre meilleur effort long (10-60min)',
  },
  workout: {
    workout: 'Séance d\'entraînement',
    training: 'Entraînement',
    library: 'Bibliothèque de séances d\'entraînement',
    new: 'Nouvelle séance d\'entraînement',
    saved: 'Séance d\'entraînement sauvegardé.',
    create_workout: 'Créer une séance d\'entraînement',
    edit_workout: 'Editer la séance d\'entraînement',
    clone_workout: 'Dupliquer la séance d\'entraînement',
    delete_workout: 'Supprimer la séance d\'entraînement',
    plan_workout: 'Planifier une séance',
    your_workouts: 'Vos séances',
    vo2fast_workouts: 'Séances VO2Fast',
    next_workouts: 'Prochaines séances',
    step: 'Étape',
    steps: 'Étapes',
    repeatitions: 'Répétitions',
    add_step: 'Ajouter une étape',
    add_repeat: 'Ajouter une répétition',
    duplicate_step: 'Dupliquer cette étape',
    other: 'Autre',
    test: 'Test',
    recovery: 'Récuperation',
    endurance: 'Endurance',
    tempo: 'Tempo',
    threshold: 'Seuil',
    vo2max: 'Vo2Max',
    anaerobic: 'Anaérobique',
    sprint: 'Sprint',
    estimations: 'Estimations',
    target: 'Cible',
    cadence_target: 'Cible de cadence',
    bind_with: 'Lier avec ',
    unbind: 'Délier',
    planned: 'Planifié',
    mark_as_completed: 'Marquer la séance comme faite',
    completed: 'Complété',
    interval: 'Effort',
    warmup: 'Echauffement',
    cooldown: 'Cooldown',
    text_description: 'Description textuelle des étapes',
    end_lap_button: 'Finir l\'étape avec la touche LAP.',
    press_lap_button: 'Appuyez sur la touche LAP pour continuer à l\'étape suivante',
    today_workout: 'Entraînement du jour',
    tomorrows_workout: 'Entraînement de demain',
    workout_in_n_days: 'Entraînement dans {n} jours',
    upcoming_workouts: 'Entraînements à venir',
    rest: 'Repos',
    change_to_power: 'Changer en puissance',
    change_to_hr: 'Change en FC',
    change_to_rpe: 'Changer en RPE',
    export_to_zwift: 'Exporter pour Zwift',
    zwift_ftp_title: 'N\'oubliez pas de mettre à jour votre FTP Zwift',
    zwift_ftp_message: 'Votre FTP actuelle est de {ftp}W. Avant de démarrer votre entraînement, n\'oubliez pas de mettre à jour votre FTP Zwift à cette valeur pour avoir les bonnes cibles.',
    export_help: 'Pour avoir vos séances sur Zwift, exportez et téléchargez le fichier Zwift de la séance et mettez le dans le dossier workouts de Zwift. Pour plus d\'informations <a href="https://www.zwift.com/news/11792-importing-and-sharing-custom-zwift-workouts">cliquez ici.</a>',
    validate_workout: 'Valider la séance',
    did_you_do_workout: 'Avez-vous effectué cette séance ?',
    did_another_ride: 'J\'ai effectué un autre entraînement.',
    not_done_yet: 'Je ne l\'ai pas encore faite.',
    not_training_today: `Je ne m'entraîne pas aujourd'hui`,
    add_activity: 'Ajouter l\'activité de la séance',
    validate_without_file: 'Valider ma séance sans fichier d\'activité',
    add_ride: 'Détails de votre entraînement',
    text_description_help: 'Guide pour créer une séance',
    text_description_help_content: `
    <p>L'utilisation de la description textuelle pour créer une séance d'entraînement sera la façon la plus rapide de le faire une fois que vous saurez comment faire.</p> <p>
    <p>D'abord, pour décrire vos étapes. Chaque étape doit être séparée par une virgule. Une étape peut être définie comme ceci : <br/>
    <b>Temps@cible</b><br/>
    Où le temps sera le temps passé dans l'étape et peut être décrit comme ceci : h:mm:ss (exemple 1:23:00 pour 1h23).<br/>
    Vous pouvez omettre les heures (ou les minutes) si elles valent 0. Par exemple 5:00 sera 5min et 37 sera 37s.<p/>
    <p>L'objectif sera soit une cible de puissance, une cible de fréquence cardiaque ou une cible RPE. (Et vous pouvez aussi ajouter une cible de cadence).<br/>
    Si vous voulez une cible de puissance, vous pouvez cibler soit un % de la ftp, un % de PMA (puissance maximale aérobie), un % de puissance maximale (puissance de sprint), ou un % de capacité anaérobique. <br/>
    <b>Exemples :</b><br/>
    <b>5:00@95%ftp</b> ciblera 95% de votre ftp pendant 5 minutes.<br/>
    <b>5:00@95%map</b> visera 95% de votre PMA pendant 5 minutes.<br/>
    <b>10@100%max</b> visera 100% de votre puissance maximale pendant 10 secondes.<br/>
    <b>5:00@50%wbal</b> visera une cible de puissance qui videra votre capacité anaérobie de 50% sur 5 minutes.</p>
    Pour une cible de fréquence cardiaque, vous pouvez soit cibler un % du seuil fc, soit un % de votre fc max: <br/>
    <b>5:00@100%hrt</b> ciblera votre fréquence cardiaque seuil pendant 5min.<br/>
    <b>5:00@95%hrmax</b> ciblera 95% de votre fréquence cardiaque maximale pendant 5min.</p>
    <br>Si vous souhaitez cibler le RPE, vous pouvez le cibler avec le nombre(1-10)+rpe. Ex : <b>5:00@7rpe</b>.<br/>
    Vous pouvez également ajouter un objectif de cadence avec le nombre de rpm cible. Exemple <b>5:00@70%ftp80rpm</b></p>
    <p>Vous pouvez également écrire la répétition des étapes avec cette forme : nb*(étapes).<br/>
    <b>Exemples :</b><br/>
    <b>4*(10:00@100%ftp, 5:00@50%ftp)</b> créera 4 répétitions d'intervalles de 10min de ftp avec 5min de repos.<br/>
    Votre répétition de pas peut aussi contenir une autre répétition de pas. Par exemple :<br/>
    <b>4*(10*(30@100%map, 30@50%ftp), 5:00@50%ftp)</b> créera 4 intervalles de 30/30 avec 5min de repos entre.</p>
    <h5 class="title is-5">Options</h5>
    <p><b>Bouton Lap</b><br/>
    Sur chaque étape, vous pouvez ajouter l'option qui nécessite d'appuyer sur le bouton lap (pour Garmin) pour passer à l'étape suivante.
    Ceci est utile pour laisser l'utilisateur aller sur un endroit approprié pour faire son intervalle au lieu de le démarrer automatiquement.<br/>
    Pour ajouter cela, il suffit d'ajouter lap à la fin de l'étape. Exemple : 5:00@50%ftp lap<br/>
    Par défaut, le bouton lap sera requis pour chaque étape de récupération entre les intervalles de plus de 3 minutes. Si vous ne souhaitez pas ce comportement, vous pouvez ajouter !lap à la fin de votre étape.</p>`,
  },
  settings: {
    profile: 'Mon profil',
    display_preferences: 'Préférences d\'affichages',
    save: 'Enregistrer',
    language: 'Langage',
    settings_saved: 'Changement de paramètres sauvegardé.',
  },
  equipment:{
    equipment: 'Matériel',
    heart_rate_monitor: 'Capteur de fréquence cardiaque',
    power_meter: 'Capteur de puissance',
    home_trainer_power: 'Home trainer avec puissance',
        help: 'Sélectionnez les équipements que vous pouvez utiliser pendant vos entraînements.',
  },
  objective: {
    objectives: 'Objectifs',
    objective: 'Objectif',
    my_objectives: 'Mes objectifs',
    add_objective: 'Ajouter un objectif',
    new_objective: 'Nouvel objectif',
    edit_objective: 'Editer objectif',
    remove_objective: 'Supprimer objectif',
    skills: 'Qualités',
    add_skill: 'Ajouter une qualité',
    threshold: 'Seuil',
    sprint: 'Sprint',
    anaerobic: 'Anaérobie',
    max_aerobic: 'PMA',
    endurance: 'Endurance',
    flat: 'Plat',
    hilly: 'Vallonné',
    climbing: 'Montagne',
    timetrial: 'Contre la montre',
    stage: 'Course à étapes',
    profile_type: 'Type de profil',
    sprinter: 'Sprinteur',
    puncher: 'Puncheur',
    climber: 'Grimpeur',
    rouleur: 'Rouleur',
    grandfondo: 'Cyclosportives/Endurance',
    estimated_duration: 'Durée estimée de la course',
    priority: 'Priorité',
    only_show: 'Afficher: ',
  },
  training_ride: {
    training_ride: 'Sortie planifiée',
    training_rides: 'Sorties planifiées',
    plan_workout_or_training_ride: 'Souhaitez-vous planifier une séance d\'entraînement ou une sortie ?',
    plan_workout_or_training_ride_desc: 'Planifiez une séance si vous souhaitez suivre une séance spécifique avec des instructions. Sinon planifiez une sortie pour avec une durée et une intensité estimé (pour une sortie de groupe par exemple). Votre plan d\'entraînement sera automatiquement mis à jour si vous planifiez une sortie.',
    plan_training_ride: 'Planifier une sortie',
    new_training_ride: 'Planifier une nouvelle sortie',
    old_training_ride: 'Ajouter une sortie',
    add_training_ride: 'Ajouter une sortie planifiée',
    edit_training_ride: 'Editer la sortie planifiée',
    remove_training_ride: 'Supprimer une sortie planifiée',
    estimated_distance: 'Distance estimé',
    estimated_elevation: 'Dénivelé estimé',
    estimated_duration: 'Durée estimée',
    planned_intensity: 'Intensité prévue',
    intensity_done: 'Intensité réalisé',
    mark_as_completed: 'Marquer la sortie planifiée comme faite.',
    unbind: 'Délier la sortie planifiée',
    did_you_do_training_ride: 'Avez-vous effectué cette sortie ?',
  },
  subscription: {
    subscription: 'Abonnement',
    my_subscription: 'Mon abonnement',
    subscribe: 'M\'abonner',
    subscribe_now: 'M\'abonner maintenant',
    what_you_get: 'Avec cet abonnement, vous aurez accès à un plan d\'entraînement personnalisé, adapté à vos objectifs, à vos disponibilités pour chaque semaine et à votre niveau d\'entraînement. Vous aurez également accés à la bibliothèque de séance de VO2Fast.',
    monthly: 'Mensuel',
    annually: 'Annuel',
    billed_annually: 'Facturation annuelle',
    billed_monthly: 'Facturation mensuelle',
    per_year: 'par an',
    per_month: 'par mois',
    thanks: 'Merci pour votre souscription',
    being_treated: '<p>Votre abonnement est en cours de traitement. Attendez quelques secondes et recharger la page pour obtenir l\'accés à toutes les fonctionnalités de l\'application. Si aprés quelques minutes vous n\'avez toujours pas d\'accès, contactez nous ici: <a href="mailto:contact@vo2fast.com">contact@vo2fast.com</a></p>',
    payment_cancelled: 'Votre paiement a été annulé.',
    manage_billing: 'Gérer vos paramètres d\'abonnement',
    subscribe_to_standard_access: 'Abonnez vous à au moins VO2Fast STANDARD pour avoir accès à cette fonctionnalité.',
    subscribe_to_premium_access: 'Abonnez vous à VO2Fast PREMIUM pour avoir accès à cette fonctionnalité.',
    subscribe_to_get_access: 'Abonnez vous pour obtenir l\'accès aux plans d\'entraînements',
    subscribe_to_get_vo2_workouts: 'Abonnez vous pour obtenir l\'accès à la bibliothèque de séance d\'entraînement VO2Fast',
    require_premium_subscription: 'Cette fonctionnalité n\'est disponible qu\'avec VO2Fast Premium. <a href="/profile">Cliquez ici pour changer d\'abonnement.</a>',
    days_free: 'Si vous êtes encore en période d\'essai gratuit, en vous abonnant maintenant vous serez débité à la fin de votre période d\'essai gratuit.',
    currently_special_access: 'Vous avez actuellement un accés spécial avec votre compte ',
    currently_not_subscribed: 'Vous n\'êtes actuellement pas abonné. Pour avoir accés à plus de fonctionnalités et à des plans d\'entraînements non limités, vous pouvez vous abonner juste en dessous.',
    currently_free_trial: 'Vous êtes actuellement en période d\'essai gratuit avec un abonnement {type} jusqu\'au {date}. Si vous aimez nos plans d\'entraînements, vous pouvez vous abonner plus bas.',
    currently_subscribed: 'You êtes actuellement abonné avec un compte {type} jusqu\'au {date}.',
    recommended_plan: `Ci-dessous le plan qui vous a été recommandé. Si vous voulez tester l'application vous pouvez également continuer avec la version gratuite pour le moment.`,
    select_plan: 'Sélectionner un abonnement',
    select_another_plan: 'Sélectionner un autre abonnement',
    select_plan_text: `Si vous souhaitez vous abonnez, veuillez sélectionner un plan d'abonnement. Vous pouvez également continuer avec la version gratuite pour le moment si vous le souhaitez.`,
  },
  plan: {
    my_plan: 'Mon plan',
    plan_data: 'Données du plan',
    no_current_plan: 'Vous n\'avez pas de plan d\'entraînement en cours.',
    generate_training_plan: 'Générer un plan d\'entraînement',
    update: 'Mettre à jour le plan',
    want_update: 'Mettre à jour votre plan entier ?',
    update_details: 'Suite au changement que vous avez fait avec un de vos objectifs principaux, voulez-vous mettre à jour la totalité de votre plan pour prendre en compte ce changement ?',
    regenerate_all_workouts: 'Regénérer toutes les séances',
    regenerate_my_plan: 'Regénérer mon plan',
    want_regenerate: 'Voulez-vous regénérer votre plan ?',
    regenerate_details: `Si vous choisissez de poursuivre, cela vous générera un tout nouveau plan qui recommencera du début et supprimera votre plan actuel.
    Si vous voulez garder votre plan mais changer des données (disponibilités ou niveau d'entraînement), vous pouvez le faire depuis votre page de profil.
    Si vous voulez ajouter/supprimer/éditer des objectifs principaux (A), vous pouvez également le faire depuis votre page de profil ou depuis le calendrier, l'application vous demandera alors si vous voulez mettre à jour votre plan actuel pour les prendre en compte.`,
    update_my_plan: 'Mettre à jour mon plan',
    delete_my_plan: 'Supprimer mon plan',
    training_time: 'Temps d\'entraînement',
    want_delete: 'Etes vous sur de vouloir supprimer votre plan ?',
    delete_details: 'Si vous continuez, votre plan entier (incluant tout les blocs et toutes les séances d\'entraînements) sera supprimé.',
    this_week_training_time: 'Temps d\'entraînement de cette semaine',
    estimated_annual_time: 'Estimation du temps d\'entraînement annuel',
    week_training_time: 'Temps moyen disponible d\'entraînement par semaine',
    max_week_training_time: 'Temps maximum disponible d\'entraînement pendant une semaine',
    day_training_time: 'Temps disponible d\'entraînement pour chaque jour',
    practice_years: 'Année(s) de pratique',
    start_date: 'Date de début du plan',
    success: 'Le plan a été généré.',
    time_updated: 'Votre temps d\'entraînement a été mis à jour.',
    include_preparation_week: 'Inclure une première semaine de reprise.',
    include_testing_week: 'Inclure une semaine de testing pour bien calibrer les entraînements qui suivront.',
    regenerate_week: 'Regénérer la semaine',
    week_time: 'Temps semaine',
    change_week_time: 'Changer vos disponibilités',
    change_global_week_time: 'Changer vos disponibilités globales',
    reload_default_week_time: 'Remettre les temps par défauts',
    generating_plan: 'Votre plan d\'entraînement est en cours de génération. Cela peut prendre quelques minutes.',
    updating_plan: 'Mise à jour de votre plan en cours.',
    selected_week: 'Semaine sélectionnée',
    indoor: 'Home trainer',
    outdoor: 'En extérieur',
    delete_workout_add_activity: 'Supprimer la séance et ajouter mon activité',
    delete_workout_import_activity: 'Supprimer la séance et télécharger mon activité',
    delete_workout_wait_activity: 'Supprimer la séance et attendre mon activité Garmin/Strava',
    free_limit_days: 'Avec votre compte gratuit, vous êtes limité à 3 séances par semaines et une séance home trainer par semaine. Si vous voulez un plan non limité, vous pouvez vous <a href="/profile">abonnez ici.</a>',
    free_limit_objectives: 'Avec votre compte gratuit, vous êtes limité à 1 objectif principal sur votre plan d\'entraînement. Si vous voulez planifier plus d\'objectifs, vous pouvez vous <a href="/profile">abonnez ici.</a>',
    blocks: {
      block: 'Bloc',
      blocks: 'Blocs',
      type_block: 'Bloc de {block}',
      transition: 'transition',
      testing: 'testing',
      preparation: 'reprise',
      base: 'développement',
      build: 'spécifique',
      recovery: 'récupération',
      taper: 'affutage',
      race: 'course',
    },
    training_level: 'Votre niveau d\'expérience avec l\'entraînement par intervalles.',
    levels: [
      'Débutant',
      'Novice',
      'Intermédiaire',
      'Avancé',
      'Expert',
      'Pro',
    ],
    help: {
      syncs: 'Synchronisez vos activités (ou importez les manuellement). Ceci permettra de calculer votre profil de puissance (si vous avez des données de puissance) et votre profil de fréquence cardiaque (si vous avez des données de fréquence cardiaque). Synchroniser avec Garmin vous permettra également d\'avoir vos séances d\'entraînements directement sur votre Garmin.',
      start_date: 'Votre plan commencera le jour que vous entrez ici.',
      practice_years: 'Le nombre d\'années durant lesquels vous avez pratiqué un sport d\'endurance.',
      week_training_time: 'Sélectionnez le nombre d\'heures moyen par semaine que vous souhaitez allouer à votre entraînement. Attention les semaines d\'entraînement peuvent être plus longue ou plus courte que la moyenne.',
      estimated_annual_time: 'Le temps estimé d\'entraînement sur l\'année basé sur le nombre d\'heures moyen d\'entraînement par semaine.',
      max_week_training_time: 'Sélectionnez votre temps maximum disponible pour l\'entraînement par semaine. Cela met un plafond strict sur le temps d\'entraînement pour chaque semaine.',
      day_training_time: 'Sélectionnez votre temps maximum disponible pour l\'entraînement pour chaque jour. Laissez à 0 pour les jours où vous ne pouvez pas vous entraîner.',
      objectives: 'Planifiez vos objectifs ici. Vous devez au moins planifier tout vos objectifs de priorité A pour que les blocs d\'entraînement soient correctement générés. Vous pourrez ensuite ajouter tout vos objectifs secondaires (B et C) dans le calendrier. Si vous n\'avez pas d\'évenement particulier à viser, votre objectif peut simplement être une date pour laquelle vous souhaitez être en forme.',
      include_preparation_week: 'Cochez cette option si vous voulez inclure une semaine de préparation pour votre première semaine d\'entraînement. Cette option est recommandé si vous n\'avez pas encore sérieusement débuté l\'entraînement pour votre saison.',
      include_testing_week: '(Recommandé) Cochez cette option si vous voulez inclure une semaine de testing au début de votre plan d\'entraînement. Durant cette semaine vous aurez à faire plusieurs tests ce qui permettra d\'établir correctement votre profil de puissance (si vous avez un capteur de puissance ou un home trainer avec des données de puissance) et votre profil de fréquence cardiaque (si vous avez un capteur de fc). Cette option est recommandé afin d\'établir vos zones d\'entraînements correctement.',
      training_level: `Estimez votre expérience avec les entraînements stucturés: <br/>
                        <b>Débutant total:</b> Vous avez débuté le cyclisme trés récemment. <br/>
                        <b>Novice:</b> Vous n'avez (presque) pas d'expérience avec les entraînements structurés. <br/>
                        <b>Intermédiaire:</b> Vous avez un peu d'expérience avec les entraînements structurés. <br/>
                        <b>Avancé:</b> Vous avez 2 ans ou plus d'expérience avec les entraînements structurés. <br/>
                        <b>Expert:</b> Vous avez plusieurs années d'expérience avec les entraînements structurés. <br/> `,
      training_level_base: `Estimez votre expérience avec les entraînements stucturés (avec la version gratuite vous n'avez accès qu'aux plans pour débutant ou novice): <br/>
                        <b>Débutant total:</b> Vous avez débuté le cyclisme trés récemment. <br/>
                        <b>Novice:</b> Vous n'avez (presque) pas d'expérience avec les entraînements structurés. <br/>`,
    },
  },
  onboarding: {
    skip: 'Passer',
    previous: 'Précédent',
    next: 'Suivant',
    finish: 'Finir',
    calendar0: `Bienvenue sur votre calendrier. Vous venez de générer un plan, nous vous proposons un petit tour de ce qui est possible de faire pour mettre à jour le plan.`,
    calendar1: `Tout d'abord, pour ajouter un élément ou modifier votre plan, vous avez la possibilité de le faire depuis ce bouton +. Sur PC vous pouvez également le faire directement dans les cases du calendrier.`,
    calendar2: `Pour chaque semaine vous avez la possibilité de changer vos disponibilités. Le plan de la semaine se mettra automatiquement à jour à chaque changement. Si vous voulez modifier vos disponibilités globales pour toute les semaines, vous pourrez le faire depuis votre profil, le plan entier sera alors regénéré.`,
    calendar3: `Vous avez également la possibilité de rajouter des objectifs (ou de les supprimer depuis le calendrier). Vous pouvez également voir la liste de vos objectifs depuis votre profil. Le plan sera recalculé pour les prendre en compte à chaque changement.`,
    calendar4: `En plus des objectifs, vous pourrez également planifier des sorties d'entraînements dans la semaine (si vous avez une sortie de groupe de prévue par exemple). Le plan les prendra en compte, assurez vous juste de prévoir l'intensité de votre sortie correctement.`,
  },
  coaching: {
    coach: 'Coach',
    coaching: 'Coaching',
    plan_data: 'Données du plan',
    quit_user: 'Quitter le compte utilisateur',
    athlete: 'Athlète',
    athletes: 'Athlètes',
    invite: 'Inviter des athlètes',
    to_invite: 'Pour inviter des athlètes à vous donner accés a leur données d\'entraînement, envoyez leurs ce lien: ',
    want_to_coach: 'vous a envoyé ce lien parce qu\'il veut vous coacher, si vous acceptez il aura accès a vos données d\'entraînement et pourra vous planifier des séances d\'entraînements.',
    login_to_accept: 'Vous devez d\'abord vous connectez ou vous inscrire pour accepter.',
    is_now_your_coach: ' est maintenant votre coach.',
    already_have_coach: 'Vous avez déjà un coach, accepter ce nouveau coach remplacera l\'ancien.',
    revoke_athlete: 'Arrêter de le coacher',
    is_your_current_coach: ' est votre coach actuellement.',
    revoke_coach: 'Arrêter le coaching',
    coach_revoked: 'Coaching arrêté.',
  },
  meta: {
    description: 'Deviens plus rapide avec VO2Fast, plans d\'entraînements dynamiques et outils d\'analyses pour améliorer vos performances.',
  },
  offline: {
    offline: 'Vous êtes hors-ligne. L\'application a besoin d\'une connexion internet pour fonctionner correctement.',
    reload: 'Recharger',
  },
  cookies: {
    message: 'Ce site web utilise des cookies pour assurer la meilleure expérience possible sur notre site.',
    got_it: 'J\'accepte !'
  },
  errors: {
    error: 'Erreur',
    page_load: {
      loading_error: 'Une erreur de chargement est survenue.',
      loading_error_description: 'Une erreur est survenue pendant le chargement d\'un des composants. Recharger la page pourrait résoudre le problème.',
      reload_page: 'Recharger',
    },
    account: {
      enter_email: 'Veuillez entrer un email.',
      enter_email_or_username: 'Veuillez entrer votre email ou votre nom d\'utilisateur.',
      invalid_email: 'Email invalide.',
      invalid_password: 'Mot de passe invalide.',
      invalid_new_password: 'Nouveau mot de passe invalide.',
      enter_name: 'Veuillez entrer un pseudo.',
      enter_password: 'Veuillez entrer un mot de passe.',
      username_not_email: 'Le nom d\'utilisateur ne peut pas être un email.',
      different_password: 'Les deux mots de passe sont différents.',
      incomplete_data: 'Données incomplètes.',
      bad_login: 'Données de connexion incorrectes.',
      invalid_token: 'Token invalide.',
      token_expired: 'Le lien a expiré.',
      unknown_user_id: 'ID utilisateur inconnue',
      already_deleted: 'Compte déjà supprimé.',
      invalid_code: 'Code invalide',
      already_used_code: 'Vous avez déjà utilisé un code.',
      code_already_used: 'Ce code a déjà été utilisé.',
      invalid_referral: 'Code de parrainage invalide.',
    },
    general: {
      enter_name: 'Veuillez entrer un nom.',
      invalid_date: 'Date invalide.',
    },
    activity: {
      not_exist: 'L\'activité n\'existe pas !',
      invalid: 'Activité invalide.',
      duplicate: 'L\'activité éxiste déjà.',
    },
    measure: {
      invalid: 'Mesure invalide.',
    },
    workout: {
      invalid: 'Séance d\'entraînement invalide.',
    },
    objective: {
      invalid: 'Objectif invalide.',
      limited_base: 'En version gratuite, vous êtes limité à un seul objectif principal (A)',
    },
    training_ride: {
      invalid: 'Sortie invalide.',
      pick_intensity: 'Vous devez choisir une intensité pour votre sortie.',
    },
    plan: {
      week_time_0: 'Vous devez entrer le temps d\'entraînement moyen (et max) que vous êtes prêt à suivre.',
      days_time_0: 'Vous devez entrer le temps d\'entraînement que vous avez de disponible pour chaque jour de la semaine.',
      training_days_less_2: 'Vous devez avoir au moins 2 jours d\'entraînements.',
      days_time_inferior_max_week_time: 'Votre temps maximum d\'entraînement par semaine ne peut pas être inférieur à la somme des temps d\'entraînements disponible des jours de la semaine.',
      no_A_objective: 'Vous devez avoir au moins un objectif de priorité A.',
      no_training_level: 'Vous devez spécifier votre niveau d\'entraînement.',
      free_limit_days: 'Avec votre compte gratuit, vous êtes limité à 3 séances par semaines et une séance home trainer par semaine. Si vous voulez un plan non limité, vous pouvez vous abonnez depuis votre page de profil.',
    },
    invalid_data: 'Invalid data.',
  },
  notfound: '404 Page introuvable !',
  notifications: frenchNotifications,
  doc: frenchDoc,
  privacy_policy: frenchPrivacyPolicy,
  terms: frenchTerms,
  legal: frenchLegal,
  workouts_lib: frenchWorkouts,
  mails: frenchMails,
  landing: frenchLanding,
  faq: frenchFAQ,
  whatsapp_link: 'https://chat.whatsapp.com/HxITLX4mU1dCeXC4rPQ3so',
  roadmap_link: 'https://app.loopedin.io/vo2fast',
};

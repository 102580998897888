import Vue from 'vue/dist/vue.js';
import App from './App.vue';
import router from './router';
import store from './stores/store.js';
import Axios from 'axios';
import { mixinUtil } from './helpers/util.js';
import i18n from './plugins/vuei18n.js';
import VueUnits from 'vue-units';
import VueGtag from "vue-gtag";
import Buefy from 'buefy';
import { LocalDate } from '@/helpers/dateUtil.js';
import './plugins/units.js';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import './registerServiceWorker';
import VueMeta from 'vue-meta';
import VueTour from 'vue-tour';
import 'vue-tour/dist/vue-tour.css';
import { VueReCaptcha } from 'vue-recaptcha-v3'




mixinUtil();

Vue.use(VueUnits);
Vue.use(VueTour);
Vue.use(VueMeta);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA3,
  loaderOptions: { autoHideBadge: true, explicitRenderParameters : {  } },
});


//If local, dont use google analytics
if(!(location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === '')){
  Vue.use(VueGtag, {
    config: {
      id: "UA-154362146-2",
    },
    appName: 'VO2Fast',
    pageTrackerScreenviewEnabled: true,
    deferScriptLoad: true,
  }, router);
}

Vue.use(Buefy);


Vue.prototype.$store = store;

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.config.productionTip = false;

Vue.prototype.$eventBus = new Vue(); //event bus

Vue.prototype.LocalDate = LocalDate;

Vue.prototype.$metaInfos = document.referrer;


Sentry.init({
  Vue,
  dsn: "https://8bcf84d78bcb44b1ad55ebf589c95fc3@o1131708.ingest.sentry.io/6176467",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [/*"localhost",*/ "app.vo2fast.com", /^\//],
    }),
  ],
  tracesSampleRate: 0.1,
  enabled: process.env.NODE_ENV !== 'development',
});


//Override console.error function to send error to sentry
(function(){
  if(window.console && console.error){
    var old = console.error;
    console.error = function(){
      try{
        old.apply(this, arguments);
        Sentry.captureException(arguments);
      }catch(err){
        console.log(err);
        Sentry.captureException(err);
      }
    }
  }
})();

let vueInstance = new Vue({
  i18n,
  router,
  render: h => h(App),
  data(){ return { width: window.innerWidth }; },
  methods: {
    isMobile(){ return this.width <= 768; },
    isTablet(){ return this.width > 768; },
    isTabletOnly(){ return this.width > 768 && this.width < 1024; },
    isTouch(){ return this.width < 1024; },
    isDesktop(){ return this.width >= 1024; },
  },
  created(){ addEventListener('resize', () => { this.width = innerWidth; }); },
}).$mount('#app');


store.$app = vueInstance;

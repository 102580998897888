module.exports = {
  general: {
   hi: 'Hi',
   thanks: 'Thanks',
   fallback: 'If you’re having trouble with the button above, copy and paste the URL below into your web browser.',
   vo2fast_team: 'VO2Fast Team',
  },
  email_verification: {
   subject: '[VO2Fast] Please verify your email adress.',
   preheader: 'Use this link to verify your email.',
   requested: 'Thank you for choosing VO2Fast. <br/> To complete your registration, use the button below to verify your email.',
   verify: 'Verify',
  },
  free_trial_about_to_end: {
    subject: '[VO2Fast] Your free trial period is about to end !',
    free_trial_end: `<b>Your free trial period is about to end !</b> <br/>
    In less than 5 days you will have to subscribe to VO2Fast if you want to continue using our training plans.<br/>
    You can subscribe to VO2Fast by clicking here 👇`,
    subscribe: 'Subscribe to VO2Fast',
  },
  free_trial_end: {
    subject: '[VO2Fast] Your free trial period ended !',
    free_trial_end: `<b>Your free trial period ended !</b> <br/>
    If you want to continue using our training plans, you will have to subscribe to VO2Fast.<br/>
    You can subscribe to VO2Fast by clicking here 👇`,
    subscribe: 'Subscribe to VO2Fast',
  },
  delete_account: {
   subject: 'VO2Fast account deletion',
   preheader: 'Use this link to delete your account. The link is only valid for one hour.',
   requested: 'You recently requested to delete your VO2Fast account. Use the button below to delete it. <strong>This will be only valid for the next hour.</strong>',
   delete: 'Delete your account'
  },
  reset_password: {
   subject: 'VO2Fast password reset',
   preheader: 'Use this link to reset your password. The link is only valid for one hour.',
   requested: 'You recently requested to reset your password for your VO2Fast account. Use the button below to reset it. <strong>This password reset is only valid for the next hour.</strong>',
   reset: 'Reset your password',
   ignore: 'If you did not request a password reset, please ignore this email.'
  },
  reset_password_not_found: {
   preheader: 'We received a request to reset your password with this email address',
   requested: 'We received a request to reset the password to access VO2Fast with your email address',
   requested2: 'but we were unable to find an account associated with this address.',
   requested3: 'If you use VO2Fast and were expecting this email, consider trying to request a password reset using the email address associated with your account.',
   try: 'Try a different email',
   ignore: 'If you do not use VO2Fast or did not request a password reset, please ignore this email.'
  },
  notifications: {
    stop: 'If you want to stop these mails notifications, you can disable them here: https://app.vo2fast.com/settings',
  },
  referrals: {
    notification_subject: '[VO2Fast] Your referral code have been used',
    notification: ` just signed up with your referral code and will now begin his free trial month.`,
    notification_paid_subject: '[VO2Fast] Someone with your referral code just took a subscription !',
    notification_paid: ` just took a paid subscription with VO2Fast. As a reward you received an additionnal free month on your subscription.`,
  },
};
